<template>
  <div id="developer">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <h1>Developer</h1>
      <b-form class="mb-4" @submit.prevent="onSubmit" @reset="onReset">
        <b-form-group label="Action" label-for="txtAction">
          <b-form-input
            id="txtAction"
            placeholder="e.g. exportinvoicecsv, addmetouserorganisations, removemefromuserorganisations"
            v-model="action"
            :state="isValidAction"
          />
          <b-form-invalid-feedback class="inline-error"
            >Action is not valid</b-form-invalid-feedback
          >
        </b-form-group>

        <b-form-group label="Parameters" label-for="txtParams">
          <b-form-input
            id="txtParams"
            placeholder="comma delimited parameters"
            v-model="params"
            :state="isValidParams"
          />
          <b-form-invalid-feedback class="inline-error"
            >Amount has to be a number</b-form-invalid-feedback
          >
        </b-form-group>

        <b-button type="submit" variant="primary" class="mr-2">Perform Action</b-button>

        <b-button variant="secondary" type="reset">Clear</b-button>
      </b-form>
    </b-container>
  </div>
</template>

<style lang="sass">
</style>

<script>
import { EventBus } from "@/components/eventbus";
import Console from "@/console";
import ApiHelper from "@/helper/apihelper";

export default {
  name: "developer",
  watch: {
    action: function (value) {
      if (!value) {
        this.isValidAction = null;
      }
    },
    params: function (value) {
      if (!value) {
        this.isValidParams = null;
      }
    }
  },
  data() {
    return {
      title: "Developer",
      action: null,
      params: null,
      isValidAction: null,
      isValidParams: null
    };
  },
  methods: {
    async onSubmit() {
      if (this.isValid()) {
        try {
          var url = `${ApiHelper.endPoint()}dev?action=${this.action}${this.urlParams()}`;

          Console.log(url);

          let client = await ApiHelper.http();
          let response = await client.get(url);

          Console.log(response);

          if (response.data.status === "success") {
            let message = "Developer action performed successfully.";
            if (response.data.output) {
              message += "<br><br>The following information was returned:\n";
              message += response.data.output.replace(/(?:\r\n|\r|\n)/g, "<br>");
            }

            EventBus.$emit("show-toast", {
              message: message,
              variant: "success"
            });

            this.onReset();
          }
        } catch (err) {
          Console.error(err);
          EventBus.$emit("show-toast", {
            message: "Developer action failed!",
            variant: "warning"
          });
        }
      }
    },
    onReset() {
      this.action = null;
      this.params = null;
    },
    isValid() {
      var isValid = true;

      this.isValidAction = null;
      this.isValidParams = null;

      if (!this.action || this.action === "") {
        this.isValidAction = false;
      } else {
        this.isValidAction = true;
      }

      isValid = isValid && this.isValidAction;

      return isValid;
    },
    urlParams() {
      var params = [];

      if (this.params) {
        this.params.split(",").forEach((el) => {
          params.push("params=" + el);
        });
      }

      return params.length == 0 ? "" : "&" + params.join("&");
    }
  }
};
</script>
